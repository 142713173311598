 <template>
    <modal
        height="auto"
        :show="true"
        scrollable
        size="md"
        @hide="$emit('hide')"
        class="notifications-modal"
        :click-to-close="Boolean(value)"
    >
        <div slot="header">
            <div class="flex flex__align-center flex__justify-between w-full">
                <strong class="f-size-18">
                    {{ value ? 'Edit notification rule' : 'New notification rule creation'  }}
                </strong>
            </div>
        </div>
        <div slot="body">
            <form v-if="!loading" @submit.prevent="" class="pb-8 pt-10">
                <div class="flex flex__align-center mb-6">
                    <strong class="notifications-modal__label">Client</strong>

                    <multiselect v-model="client" :options="organizationsList" label="name" track-by="id" placeholder="Client name" @input="fetchRecipients" :class="{'is-invalid': submitter && $v.client.$invalid}"></multiselect>
                </div>

                <div class="flex flex__align-center mb-6">
                    <strong class="notifications-modal__label">Site</strong>

                    <multiselect v-model="site" :options="sitesListComputed" label="name" track-by="id" placeholder="Site name" multiple @input="fetchSites"></multiselect>
                </div>

                <div class="flex flex__align-center mb-6">
                    <strong class="notifications-modal__label">Notification trigger</strong>

                    <multiselect v-model="type" :options="typesList" label="label" track-by="value" placeholder="Notification trigger" @input="updateType" :class="{'is-invalid': submitter && $v.type.$invalid}"></multiselect>
                </div>
                

                <div v-if="type && type.value === xceptionValue" class="flex flex__align-center mb-6">
                    <div class="notifications-modal__label">Exception type</div>

                    <multiselect v-model="xception" :options="xceptionsList" label="label" track-by="value" placeholder="Exception type" @input="fetchTemplates(); template = null;" :class="{'is-invalid': submitter && $v.xception.$invalid}"></multiselect>
                </div>

                <div class="flex flex__align-center mb-6">
                    <strong class="notifications-modal__label">User filter query</strong>

                    <multiselect v-model="user_filter_id" :options="queriesList" label="title" track-by="id" placeholder="User filter query"></multiselect>
                </div>

                <div class="flex mb-6">
                    <strong class="notifications-modal__label">Type</strong>

                    <div class="flex">
                        <label class="flex flex__align-center cursor-pointer mr-6">
                            <input type="radio" v-model="mode" value="notification" name="mode" class="mr-2">
                            <span>Notification</span>
                        </label>

                        <label class="flex flex__align-center cursor-pointer">
                            <input type="radio" v-model="mode" value="bookmark" name="mode" class="mr-2">
                            <span>Bookmark</span>
                        </label>
                    </div>
                </div>

                <div class="flex flex__align-center mb-4">
                    <div class="notifications-modal__label">
                        <strong class="notifications-modal__label">Template</strong>
                        <button type="button" class="btn btn-sm ml-4" :disabled="isTemplateDisabled || !template || isBookmarkMode" @click="isTemplatePreviewShow = !isTemplatePreviewShow">preview</button>
                    </div>

                    <multiselect v-model="template" :options="templatesList" :disabled="isTemplateDisabled || isBookmarkMode" label="name" track-by="id" placeholder="Template name" :class="{'is-invalid': submitter && $v.template.$invalid}"></multiselect>
                </div>

                <div v-if="isTemplatePreviewShow && template" class="mb-4">
                    <pre v-show="template.content" v-text="template.content" class="notifications-modal__preview mb-2" />
                    <pre v-show="template.content_abridged" v-text="template.content_abridged" class="notifications-modal__preview" />
                </div>

                <div class="flex flex__align-center">
                    <strong class="notifications-modal__label">Notification recipients</strong>
                    <multiselect v-model="recipients" :options="recipientsList" :disabled="!client || isBookmarkMode" multiple label="full_name" track-by="key" placeholder="Notification recipients" @select="selectRecipient" @remove="removeRecipient"></multiselect>
                </div>

                <div class="pt-10">
                    <div
                        v-for="item, i in recipientsItems"
                        :key="i"
                        class="flex mb-4 flex__align-center"
                    >
                        <modal-field
                            label="Recipient name"
                            class="mr-4 w-1/3"
                        >
                            <input v-model="item.full_name" :disabled="item.user_id || isBookmarkMode" type="text" :class="{'is-invalid': submitter && !item.full_name.length }" />
                        </modal-field>

                        <modal-field v-if="!isSpecifiedRecipient(item)" label="Recipient e-mail" class="w-1/3 mr-4">
                            <input v-model="item.email" :disabled="item.user_id || isBookmarkMode" type="text" :class="{'is-invalid': submitter && (validateRecipients(item.channels, 'email') && !item.email) }" />
                        </modal-field>

                        <div class="recipient-remove-wrapper">
                            <button
                                class="border-none bg-transparent mt-4 ml-4"
                                :class="{ 'opacity-50': isBookmarkMode }"
                                :disabled="isBookmarkMode"
                                @click="deleteItem(item, i)"
                            >
                                <svg-importer icon-name="icons/close" key="recipient-close" />
                            </button>
                        </div>
                    </div>
                </div>

                <div class="flex flex__justify-end mt-6">
                    <button class="btn-blue" :disabled="isBookmarkMode" @click="addRecipient">Add new recipient</button>
                </div>

                <div ref="channels" class="pt-6 flex flex__column">
                    <strong class="mb-8">Notification type</strong>

                    <div
                        v-for="item, i in recipientsItems"
                        :key="i"
                        class="flex flex__justify-between mb-4"
                    >
                        <div :class="{ 'color-pink': submitter && !item.channels.length, 'opacity-50': isBookmarkMode }">
                            Recipient {{ i + 1 }}
                            <span v-if="item.full_name">({{ item.full_name }})</span>
                        </div>

                        <div class="flex flex__align-center">
                            <label
                                v-for="channel, channelIndex in channels(item)"
                                :key="channelIndex"
                                :for="`${channel.value}_${i}`"
                                class="text-gray-700 font-medium relative flex flex__align-center ml-4"
                            >
                                <span
                                    class="leading-tight color-dark f-size-12 truncate mr-3 vcheckbox-title color-pink"
                                    :class="{ 'opacity-50': isBookmarkMode }"
                                >
                                    {{ channel.name }}
                                </span>

                                <div class="relative">
                                    <input
                                        v-model="recipientsItems[i].channels"
                                        :value="channel.value"
                                        :id="`${channel.value}_${i}`"
                                        :disabled="isBookmarkMode"
                                        type="checkbox"
                                        class="hidden"
                                    >
                                    <div class="toggle__line w-10 h-5 bg-gray-300 rounded-full mr-2" :class="{'border border-red-500': !recipientsItems[i].channels.length }" />
                                    <div class="toggle__dot absolute w-4 h-4 bg-white rounded-full inset-y-0" />
                                </div>
                            </label>

                            <button class="border-none bg-transparent" :class="{ 'opacity-50': isBookmarkMode }" :disabled="isBookmarkMode" @click="deleteItem(item, i)">
                                <svg-importer icon-name="icons/close" key="close" />
                            </button>
                        </div>
                    </div>
                </div>
            </form>

            <div v-else class="flex flex__justify-center pt-6 pb-6">
                <fa :icon="['fad', 'spinner-third']" class="brand-primary fa-spin text-4xl" />
            </div>
        </div>

        <div slot="footer" class="w__100-p border__top-grey-1 pt-6 pl-6 pr-6">
            <div class="flex justify-end w-full">
                <button
                    v-if="!value"
                    class="px-8 btn-primary"
                    :disabled="btnLoading || !recipientsItems.length"
                    @click.prevent="submit"
                >
                    Add rule
                </button>
                <button
                    v-else-if="value && value.is_editable"
                    class="px-8 btn-primary"
                    :disabled="btnLoading || !recipientsItems.length"
                    @click.prevent="submit"
                >
                   Edit rule
                </button>
            </div>
        </div>
    </modal>
</template>

<script>
import axios from 'axios';
import { required, requiredIf } from 'vuelidate/lib/validators';

const mapRecipientToServer = (recipient) => {
    if (recipient.specified_recipient_id) {
        return {
            recipient_id: recipient.specified_recipient_id,
            specified_status: recipient.specified_status,
            channels: recipient.channels,
        }
    }

    return {
        ...recipient,
        key: undefined,
        email: !recipient.user_id ? recipient.email : null,
        phone_number: !recipient.user_id ? recipient.phone_number : null,
        full_name: !recipient.user_id ? recipient.full_name : null,
    }
};

export default {
    name: 'NotificationsModal',

    props: {
        value: {
            type: Object,
            default: null
        }
    },

    data() {
        return {
            client: null,
            site: [],
            type: null,
            template: null,
            xception: null,
            user_filter_id: null,
            recipients: [],
            mode: 'notification',

            organizationsList: [],
            sitesList: [],
            typesList: [],
            templatesList: [],
            xceptionsList: [],
            recipientsList: [],
            queriesList: [],

            submitter: false,
            loading: false,
            btnLoading: false,

            recipientsItems: [
                {
                    email: '',
                    phone_number: '',
                    full_name: '',
                    user_id: null,
                    recipient_id: null,
                    channels: []
                }
            ],
            xceptionValue: 'NEW_EXCEPTION_CREATED',

            isTemplatePreviewShow: false,
        }
    },

    computed: {
        isBookmarkMode() {
            return this.mode === 'bookmark';
        },
        sitesListComputed() {
            return this.client ? this.sitesList?.filter(site => site.organization_id === this.client.id) : [];
        },
        isValidRecipients() {
            const result = this.recipientsItems.filter(recipientItem => {
                const isSpecified = recipientItem.specified_status;
                const channels = recipientItem.channels;
                const isEmail = channels.find(_item => { return _item === 'email' });

                if (isSpecified && isEmail) {
                    return false;
                }

                return !recipientItem.full_name || (isEmail && !recipientItem.email);
            });

            return Boolean(!result.length);
        },

        isValidChannels() {
            const result = this.recipientsItems.filter(item => {
                return !item.channels.length
            });

            return Boolean(!result.length);
        },

        sendValue() {
            if (this.isBookmarkMode) {
                return {
                    organization_id: this.client?.id,
                    type: this.type?.value,
                    details: this.xception?.value || null,
                    site_ids: this.site.length ? this.site.map(site => site.id) : [],
                    user_filter_id: this.user_filter_id?.id || null,
                    mode: this.mode,
                };
            }

            return {
                organization_id: this.client?.id,
                type: this.type?.value,
                details: this.xception?.value || null,
                template_id: this.template?.id,
                recipients: this.recipientsItems.map(mapRecipientToServer),
                site_ids: this.site.length ? this.site.map(site => site.id) : [],
                user_filter_id: this.user_filter_id?.id || null,
                mode: this.mode,
            };
        },

        isTemplateDisabled() {
            return !this.type || (this.type.value === this.xceptionValue && !this.xception);
        }
    },

    watch: {
        client() {
            this.site = [];
        },
    },

    methods: {
        isSpecifiedRecipient(recipient) {
            return recipient.specified_status;
        },
        channels(item) {
            const result = [
                { name: 'E-mail', value: 'email' },
            ];

            if (item.user_id) {
                result.push({ name: 'System', value: 'system' });
            }

            return result;
        },

        async setup() {
            if (!this.value) {
                return;
            }

            this.client = {
                name: this.value.organization.name,
                id: this.value.organization.id,
            }

            this.mode = this.value.mode;
            this.type = this.typesList.find(item => item.value === this.value.type);

            await this.fetchTemplates();

            this.template = this.templatesList.find(item => item.id === this.value.template_id);

            await this.fetchRecipients();

            if (this.value.details?.details) {
                this.xception = this.xceptionsList.find(item => {
                    return item.value === this.value.details?.details
                });

                await this.fetchTemplates();

                this.template = this.templatesList.find(item => { return item.name === this.value.template_name });
            }

            this.recipientsItems = this.value.recipients.map(item => ({
                ...item,
                key: item.user_id ? `user_${item.user_id}` : `recipient_${item.id}`,
                recipient_id: item.id,
            }));
            this.recipients = [...this.recipientsItems];

            this.site = this.sitesList.reduce((accum, current) => {
                if (this.value.site_ids.includes(current.id)) {
                    accum.push(current);
                }

                return accum;
            }, []);

            this.user_filter_id = this.value.user_filter;
        },

        async updateType() {
            this.xception = null;
            this.template = null;

            if (!this.type) {
                this.templatesList = [];

                return;
            }

            await this.fetchTemplates();
        },

        validateRecipients(channels, type) {
            return channels.find(item => item === type);
        },

        isNewAndEmptyRecipient(item) {
            return !item.id
                && !item.recipient_id
                && !item.user_id
                && item.full_name === ''
                && item.email === ''
                && item.phone_number === '';
        },

        addRecipient() {
            this.recipientsItems.push({
                email: '',
                phone_number: '',
                full_name: '',
                user_id: null,
                recipient_id: null,
                channels: [],
            });
        },

        selectRecipient(value) {
            const newItem = {
                ...value,
                channels: [],
            };

            if (this.recipientsItems.length === 1 && this.isNewAndEmptyRecipient(this.recipientsItems[0])) {
                this.recipientsItems.splice(0, 1, newItem);
            } else {
                this.recipientsItems.push(newItem);
            }
        },

        async deleteItem(item, index, isUpdateRecipients = true) {
            if (this.value && item.id) {
                await axios.delete(`/api/notifications/${this.value.id}/recipient/${item.id}`);
            }

            this.recipientsItems.splice(index, 1);
            if (isUpdateRecipients) {
                const isRecipientsItems = this.recipients.findIndex(_item => _item.key === item.key);
                if (isRecipientsItems > -1) {
                    this.recipients.splice(isRecipientsItems, 1);
                }
            }
        },

        async removeRecipient(value) {
            const findIndex = this.recipientsItems.findIndex(item => item.key === value.key);
            this.deleteItem(this.recipientsItems[findIndex], findIndex, false);
        },

        async fetchOrganizations() {
            try {
                const { data: { data } } = await axios.get('/api/notifications/organizations');

                this.organizationsList = data;
            } catch(error) {
                this.organizationsList = [];
            }
        },

        async fetchTypes() {
            try {
                const { data: { data } } = await axios.get('/api/notifications/types');

                this.typesList = data;
            } catch(error) {
                this.typesList = [];
            }
        },

        async fetchTemplates() {
            const value = this.xceptionsList.find(item => item?.value === this.xception?.value)?.value;

            try {
                if (!value && this.type.value !== this.xceptionValue) {
                    const { data: { data } } = await axios.get(this.$apiUrl.cannedResponses.action + '/' + `${this.type.value}`);

                    this.templatesList = data;
                    
                    return;
                }

                if (!value) {
                    return
                }
                
                const { data: { data } } = await axios.get(this.$apiUrl.cannedResponses.action + '/' + `${this.xceptionValue}:${value}`);

                this.templatesList = data;
            } catch(error) {
                this.templatesList = [];
            }
        },

        async fetchXceptions() {
            try {
                const { data: { data: { exception_details } } } = await axios.get(`${this.$apiUrl.filterOptions}filter[date_between]=2000-01-01,2000-01-01`);

                this.xceptionsList = exception_details;
            } catch(error) {
                this.xceptionsList = [];
            }
        },

        async fetchRecipients() {
            if (!this.client) {
                return;
            }

            try {
                const { data: { data: { recipients, users, specified } } } = await axios.get(`/api/notifications/${this.client.id}/recipients`);

                this.recipientsList = [
                    ...Array.isArray(users)
                        ? users.map(user => ({
                            ...user,
                            key: `user_${user.id}`,
                            id: undefined,
                            user_id: user.id,
                            recipient_id: null
                        }))
                        : [],
                    ...Array.isArray(recipients)
                        ? recipients
                            .filter(recipient => users.find(user => recipient.user_id === user.id) === undefined)
                            .map(recipient => ({
                                ...recipient,
                                key: `recipient_${recipient.id}`,
                                id: undefined,
                                recipient_id: recipient.id,
                            }))
                        : [],
                ];
                this.recipientsList.sort((a, b) => {
                    if (a.full_name > b.full_name) {
                        return 1;
                    }

                    if (a.full_name < b.full_name) {
                        return -1;
                    }

                    return 0;
                });
                this.recipientsList = specified
                    .map(item => (
                        {
                            key: `specified_recipient_${item.id}`,
                            specified_recipient_id: item.id,
                            specified_status: item.specified_status,
                            full_name: item.full_name,
                        }
                    ))
                    .concat(this.recipientsList);
            } catch(error) {
                this.recipientsList = [];
            }
        },

        async fetchSites() {
            try {
                const { data: { data } } = await axios.get(this.$apiUrl.sites.base + '?pageSize=1000');

                this.sitesList = data;
            } catch (error) {
                this.sitesList = [];
            }
        },

        async fetchUpdateNewRecipients() {
            const newRecipients = this.recipientsItems
                .filter(item => !item.id)
                .map(mapRecipientToServer);

            for (let item of newRecipients) {
                await axios.post(`/api/notifications/${this.value.id}/recipient`, item);
            }
        },

        async fetchUpdateUpdatedRecipients() {
            const updatedRecipients = this.recipientsItems
                .filter(item => item.id)
                .map(mapRecipientToServer);

            for (let item of updatedRecipients) {
                if (item.specified_status) {
                    await axios.put(
                        `/api/notifications/${this.value.id}/recipient/${item.id}`,
                        { specified_status: item.specified_status, channels: item.channels }
                    );

                    return;
                }

                await axios.put(`/api/notifications/${this.value.id}/recipient/${item.id}`, item);
            }
        },

        async fetchUpdate() {
            await axios.put(`/api/notifications/${this.value.id}`, {
                organization_id: this.client?.id,
                type: this.type?.value,
                details: this.xception?.value || null,
                template_id: this.template?.id,
                site_ids: this.site.length ? this.site.map(site => site.id) : [],
                user_filter_id: this.user_filter_id?.id || null,
                mode: this.mode,
            });
        },

        async fetchQueries() {
            const { data: { data } } = await axios.get(`${this.$apiUrl.filter.base}?filter[category]=shipments`)

            this.queriesList = data;
        },

        async submit() {
            this.submitter = true;

            if (this.$v.$invalid) {
                return;
            }

            if (!this.isValidRecipients && !this.isBookmarkMode) {
                return;
            }

            if (!this.isValidChannels && !this.isBookmarkMode) {
                this.$refs.channels.scrollIntoView({ behavior: 'smooth' });

                return;
            }

            this.btnLoading = true;

            try {
                if (!this.value) {
                    await axios.post('/api/notifications/subscribtions', this.sendValue);
                } else {
                    await this.fetchUpdate();
                    await this.fetchUpdateNewRecipients();
                    await this.fetchUpdateUpdatedRecipients();
                }

                this.submitter = false;
                this.$snotify.success(`Successfully`);
                this.$emit('save');
                this.$emit('hide');
            } catch (error) {
                const errors = error?.response?.data?.errors

                const messages = [];

                for (let key in errors) {
                    messages.push(`${key}: ${errors[key].join(', ')}`);
                }

                this.$snotify.error(messages.join(', ') || error?.response?.data?.message);
            } finally {
                this.btnLoading = false;
            }
        }
    },

    async created() {
        this.loading = true;
        this.btnLoading = true;

        await Promise.all([
            this.fetchOrganizations(),
            this.fetchSites(),
            this.fetchTypes(),
            this.fetchXceptions(),
            this.fetchQueries(),
        ]); 

        await this.setup(),

        this.loading = false;
        this.btnLoading = false;
    },

    validations: {
        client: {
            required: requiredIf(function() {
                return !this.isBookmarkMode;
            }),
        },
        type: {
            required,
        },
        template: {
            required: requiredIf(function() {
                return !this.isBookmarkMode;
            }),
        },
        xception: {
            required: requiredIf(function() {
                return this.type?.value === this.xceptionValue;
            }),
        },
    }
}
</script>

<style lang="scss">
.notifications-modal {
    &__preview {
        padding: 10px;
        border: 1px solid #000;
        border-radius: 5px;
    }
    &__label {
        max-width: 225px;
        width: 100%;
        margin-right: 5px;
    }
    .card-header {
        margin-bottom: 0;

        & > div {
            width: 100%;
        }
    }
    .modal__footer {
        padding-top: 0;
        padding-left: 0;
        padding-right: 0;
    }

    .toggle__dot {
        top: .13rem;
        left: .13rem;
        transition: all 0.3s ease-in-out;
    }

    .toggle__line {
        transition: all 0.3s ease-in-out;
    }

    .recipient-remove-wrapper {
        margin-left: auto;
    }

    input:checked ~ .toggle__line {
        @apply bg-gray-800;
    }

    input:checked ~ .toggle__dot {
        transform: translateX(125%);
    }

    input:checked ~ .no-branding.toggle__line {
        @apply bg-blue-900 #{!important};
    }

    input:disabled, input:checked:disabled {
        & ~ .no-branding.toggle__line, .toggle__line {
            @apply bg-gray-500 border-gray-500 #{!important};
        }   
    }
}
</style>
